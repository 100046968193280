.reasons {
  &__title {
    margin: 0 0 20px;

    @include respond-to(tablet) {
      margin: 0 0 30px;
    }

    @include respond-to(macbook) {
      margin: 0 0 40px;
    }
  }

  &__cards {
    @include respond-to(macbook) {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__card {
    border: 2px solid $color-main;
    border-radius: 0;
    border-bottom: none;

    padding: 20px 20px 20px 38px;

    display: flex;
    flex-direction: column;

    @include respond-to(tablet) {
      padding: 30px 30px 30px 60px;
    }

    @include respond-to(macbook) {
      border: 2px solid $color-main;
      border-right: none;
      padding-right: 20px;
      padding-left: 55px;
    }

    &:first-child {
      border-radius: 6px 6px 0 0;

      @include respond-to(macbook) {
        border-radius: 6px 0 0 6px;
      }
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
      border-bottom: 2px solid $color-main;

      @include respond-to(macbook) {
        border-radius: 0 6px 6px 0;
        border-right: 2px solid $color-main;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;
      margin: 0 0 20px;

      position: relative;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }

      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: $color-main;

        position: absolute;
        top: 6px;
        left: -18px;

        @include respond-to(tablet) {
          width: 10px;
          height: 10px;
          border-radius: 5px;
          top: 8px;
          left: -20px;
        }
      }
    }

    &-explain {
      font-size: 14px;
      line-height: 1.45;
      padding: 0 0 20px;
      border-bottom: 1px solid $color-border;
      margin: 0 0 20px;

      flex-grow: 2;

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.6666;
        padding: 0 0 30px;
        margin: 0 0 30px;
      }
    }

    &-stat {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 700;
      font-style: italic;

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.2777;
      }
    }
  }
}
