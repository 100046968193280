.review {
  &:last-child {
    &:after {
      display: none;
    }
  }

  &__item {
    position: relative;

    @include respond-to(macbook) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 50px;
    }
  }

  &__photo {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: $color-grey;
    overflow: hidden;
    margin: 0 0 30px;

    @include respond-to(tablet) {
      width: 228px;
      height: 228px;
      margin: 0;
    }

    @include respond-to(macbook) {
      position: relative;
      width: 240px;
      height: 240px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
    }
  }

  &__col {
    @include respond-to(macbook) {
      padding: 10px 0 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;
    padding: 0 0 0 120px;
    margin: 0 0 16px;

    @include respond-to(tablet) {
      min-height: 228px;
      padding: 0 0 0 258px;
      margin: 0 0 27px;
    }

    @include respond-to(macbook) {
      position: relative;
      min-height: auto;
      padding: 0 0 20px;
      margin: 0 0 17px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        display: block;
        height: 4px;
        width: 100px;
        background-color: $color-main;
      }
    }
  }

  &__name {
    text-transform: none;
    margin: 0 0 6px;

    @include respond-to(tablet) {
      margin: 0 0 20px;
    }

    @include respond-to(macbook) {
      margin: 0 0 10px;
    }
  }

  &__post {
    font-size: 14px;
    line-height: 1.3;
    font-style: italic;

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 1.25;
    }
  }
}
