.contacts {
  &__block {
    @include respond-to(tablet) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  &__item {
    margin: 15px 0;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    grid-template-columns: 16px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "icon label"
      "icon value";

    @include respond-to(tablet) {
      grid-row-gap: 9px;
      margin: 15px 0;

      flex: 0 0 100%;
      padding: 0 40px 0 0;

      @include respond-to(tablet) {
        grid-row-gap: 2px;
        margin: 13px 0;
      }
    }

    @include respond-to(desktop) {
      margin: 0;
    }

    &_short {
      @include respond-to(tablet) {
        flex: 0 0 auto;
      }
    }

    &_medium {
      @include respond-to(desktop) {
        flex: 0 0 auto;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      grid-area: icon;
      width: 16px;
      margin: 2px 0 0;

      @include respond-to(tablet) {
        width: 16px;
        margin: 5px 0 0;
      }

      @include respond-to(desktop) {
        margin: 6px 0 0;
      }
    }

    &-label {
      grid-area: label;

      font-size: 16px;
      line-height: 1.25;
      font-weight: bold;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }
    }

    &-value {
      grid-area: value;

      font-size: 14px;
      line-height: 1.3;

      text-decoration: none;

      @include respond-to(tablet) {
        font-size: 16px;
        line-height: 1.625;
      }
    }
  }

  &__map {
    height: 523px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -1 * $padding;
    background: $color-grey;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-pane {
      z-index: 95;
    }

    .leaflet-top,
    .leaflet-bottom {
      z-index: 98;
    }

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
      height: 518px;
    }

    @include respond-to(maxLayoutWidth) {
      margin: 0;
      height: 588px;
    }
  }
}

.feedback__form {
  @include respond-to(tablet) {
    max-width: 610px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 40px;
  }

  &-message,
  &-btn {
    grid-column: 1 / 3;
  }

  &-btn {
    margin: 0 auto 0 0;
  }
}
