.header {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  background: transparent;
  padding: 0 20px;

  @include respond-to(tablet) {
    height: 100px;
    padding: 0 30px;
  }

  @include respond-to(macbook) {
    height: 120px;
  }

  @include respond-to(desktop) {
    padding: 0 40px;
  }

  &-section {
    position: relative;
  }

  &__logo {
    z-index: 10;

    &-icon {
      display: none;

      svg {
        vertical-align: middle;
        width: 65px;
        height: 40px;
      }

      @include respond-to(macbook) {
        display: block;

        svg {
          width: 300px;
          height: 62px;
        }
      }

      &_mobile {
        display: block;

        @include respond-to(macbook) {
          display: none;
        }
      }
    }
  }

  &__backdrop,
  &__languages-backdrop {
    display: none;
    position: fixed;

    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: $global-transition;

    z-index: 9;
  }

  &__backdrop {
    z-index: 99;

    .nav-open & {
      display: block;
      background: rgba($color-black, 0.8);
    }
  }

  &__languages-backdrop {
    .languages-open & {
      display: block;
    }
  }

  &__buttons {
    display: flex;
  }

  &__btn {
    text-decoration: none;
    transition: $global-transition;
    margin: 0 0 0 20px;
    z-index: 10;

    &:hover {
      color: $color-main-hover;
    }

    @include respond-to(macbook) {
      display: flex;
      align-items: center;
      margin: 0 0 0 40px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      background-color: $color-blue;
      border-radius: 50%;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      transition: $global-transition;

      &:hover {
        background-color: $color-main-hover;
      }

      svg {
        fill: $color-white;
        width: 20px;
        height: 20px;
      }
    }

    &-label {
      display: none;

      @include respond-to(macbook) {
        display: inline-block;
        font-size: 18px;
        line-height: 1.28;
        font-weight: 500;
        margin: 0 0 0 15px;
      }
    }
  }

  &__burger {
    cursor: pointer;

    .nav-open & {
      z-index: 101;
    }

    &-icon {
      position: relative;

      span {
        position: absolute;
        top: 50%;
        right: 11px;
        left: 11px;
        display: block;
        height: 2px;
        background: $color-white;
        border-radius: 2px;
        margin: -1px 0 0;
        transition-duration: 0.4s, 0.1s;
        transition-delay: 0.1s;

        &::after,
        &::before {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          height: 2px;
          display: block;
          background: $color-white;
          border-radius: 2px;
          transition-duration: 0.3s, 0.3s;
        }

        &::after {
          top: -6px;
          transition-property: top, transform;
        }

        &::before {
          bottom: -6px;
          transition-property: bottom, transform;
        }
      }

      .nav-open & {
        background: $color-white;

        span {
          background: none;

          &::after,
          &::before {
            background-color: $color-blue;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition-delay: 0s, 0.3s;
          }

          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .nav-open & {
      position: fixed;
      top: 20px;
      right: 20px;

      @include respond-to(tablet) {
        top: 30px;
        right: 30px;
      }

      @include respond-to(macbook) {
        top: 40px;
        right: 40px;
      }
    }

    &-label {
      .nav-open & {
        display: none;
      }
    }
  }

  &__nav {
    margin: 0 0 30px;

    a {
      text-decoration: none;
      color: $color-white;
      transition: $global-transition;

      &:hover {
        color: $color-white;
        text-decoration: underline;
      }
    }

    &:last-child {
      margin: auto 0 0;
    }

    &-wrap {
      display: flex;
      flex-direction: column;
      background: $color-blue;
      color: $color-white;
      position: fixed;
      top: 0;
      bottom: 0;
      left: auto;
      width: 100%;
      right: -100%;
      transition: right $transition-time ease-out;
      padding: 80px $padding 60px;
      overflow-y: auto;
      z-index: 100;

      @include respond-to(tablet) {
        width: 414px;
        right: -480px;
        padding: 100px $tPadding 60px;
        box-shadow: -3px 0 5px 2px rgba($color-black, 0.3);
      }

      @include respond-to(macbook) {
        padding: 100px 40px 60px;
      }

      .nav-open & {
        right: 0;
      }
    }

    &-submenu {
      margin: 0 0 0 15px;
    }

    &-title {
      font-size: 20px;
      line-height: 1.25;
      font-weight: 700;
      margin: 0 0 20px;
    }

    &-item {
      display: block;
      font-size: 16px;
      line-height: 1.25;
      font-weight: 400;
      margin: 0 0 15px;

      a.active {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  &__languages {
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;
    padding: 5px 18px 5px 9px;
    margin: 0 -10px 0 20px;
    z-index: 10;

    .languages-open & {
      background: $color-white;
      background: transparent;
    }

    &-wrapper {
      .languages-open & {
        display: block;
        background: $color-blue;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6px 18px 4px 9px;
      }
    }

    &-item {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      width: 31px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      transition: color $transition-time ease;
      display: none;

      &:hover {
        color: $color-placeholder;
      }

      &_hide {
        display: none;
      }

      .languages-open &:not(.header__languages-item_hide) {
        color: $color-white;
        display: flex;
        width: 31px;
        height: 30px;
        border-bottom: 1px solid $color-main-hover;

        &:last-child,
        &:nth-last-child(2) {
          border: none;
        }

        &:hover {
          color: $color-border;
        }
      }

      &_value {
        display: flex;

        transition: color $transition-time ease;
        position: relative;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: $color-black transparent transparent transparent;

          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -3px;

          transition: border-color $transition-time ease;
        }

        &:hover {
          &:after {
            border-color: $color-placeholder transparent transparent transparent;
          }
        }
      }

      &_active {
        position: relative;
        display: none;

        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: $color-white transparent transparent transparent;

          margin: 0 0 0 5px;

          display: none;

          .languages-open & {
            position: absolute;
            top: 50%;
            margin-top: -3px;
            margin-left: 0;
            left: 100%;
            display: block;
            border-width: 0 4px 7px 4px;
            border-color: transparent transparent $color-white transparent;
          }
        }
      }
    }
  }

  &__search {
    &-wrap {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 70px;

      @include respond-to(tablet) {
        top: 30px;
        left: 30px;
        right: 80px;
      }

      @include respond-to(macbook) {
        top: 40px;
        left: 40px;
      }

      @include respond-to(desktop) {
        right: 90px;
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-explain-error {
      color: $color-white;
      padding: 0 40px 0 0;
    }

    &-field {
      &.ant-input-search .ant-input-affix-wrapper {
        background-color: $color-white;
        border-color: $color-white;
      }

      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right-width: 2px;
      }

      &.ant-input-search .ant-input-affix-wrapper:hover,
      &.ant-input-search .ant-input-affix-wrapper:focus {
        border-right-width: 2px !important;
      }

      &.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
        background-color: transparent;
        border: none;

        &:hover {
          border-color: $color-main;
          background: inherit;
        }
      }
    }

    .ant-input-suffix {
      display: none;
    }
  }
}
