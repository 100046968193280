.posts-feed {
  &__dropdown {
    position: relative;
    z-index: 2;
    margin: 0 0 40px;
  }

  &__wrapper {
    @include respond-to(tablet) {
      display: grid;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      grid-template-columns: repeat(auto-fill, 338px);
    }

    @include respond-to(macbook) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__item {
    margin: 20px 0;

    @include respond-to(tablet) {
      display: flex;
      flex-direction: column;
      min-width: 0;
      margin: 0;
    }

    @include respond-to(macbook) {
      margin: 0 0 30px;
    }

    &:last-child {
      margin-bottom: 0;

      @include respond-to(macbook) {
        margin: 0 0 30px;
      }
    }

    &:first-child {
      margin-top: 0;

      @include respond-to(macbook) {
        margin: 0 0 30px;
      }
    }
  }
}

.post-item {
  &__image {
    width: 100%;
    display: none;
    border-radius: 10px;
    margin: 0;

    @include respond-to(tablet) {
      display: block;
    }

    &_mobile {
      display: block;

      @include respond-to(tablet) {
        display: none;
      }
    }

    &-link {
      display: block;
      position: relative;
      margin: 0 0 18px;

      &-favorite {
        &:after {
          content: "\02605";
          position: absolute;
          right: 12px;
          bottom: 8px;
          color: white;
          font-size: 16px;
        }
      }
    }
  }

  &__date {
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0 0 5px;

    display: flex;

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 1.25;
      margin: 0 0 4px;
    }

    @include respond-to(macbook) {
      margin: 0 0 5px;
    }

    &-separator {
      margin: 0 7px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin: 5px 0 9px;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
      margin: 0 0 12px;
    }

    a {
      text-decoration: none;
    }
  }

  &__intro {
    font-size: 14px;
    line-height: 1.45;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 9px 0;

    @include respond-to(tablet) {
      line-height: 1.9;
      margin: 0 0 12px;
    }

    @include respond-to(desktop) {
      font-size: 16px;
      line-height: 1.625;
    }

    p {
      margin: 0;
    }
  }

  &__more {
    font-size: 14px;
    line-height: 1.28;
    font-weight: 700;
    color: $color-blue;
    text-decoration: none;

    transition: color $transition-time ease;

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 1.25;
      margin: auto 0 0;
    }

    &:hover {
      color: $color-main-hover;
    }
  }
}

.post {
  &__date {
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;

    display: flex;

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 1.25;
    }

    &-separator {
      margin: 0 7px;
    }
  }

  &__image {
    width: 100%;
    display: none;
    border-radius: 10px;
    margin: 20px 0 0;

    @include respond-to(tablet) {
      display: block;
    }

    &_mobile {
      display: block;

      @include respond-to(tablet) {
        display: none;
      }
    }
  }

  &__share {
    margin: 10px 0 20px;
    display: flex;
    align-items: center;

    &-label {
      font-size: 14px;
      line-height: 1.3;
      text-transform: uppercase;

      margin: 0 10px 0 0;

      @include respond-to(tablet) {
        font-size: 16px;
        line-height: 1.25;
      }
    }
  }

  &__back,
  &__more {
    @include respond-to(tablet) {
      align-self: flex-start;
    }
  }

  &__more {
    margin: 30px 0 0;

    align-self: center;

    @include respond-to(tablet) {
      min-width: $buttonWidth;
      width: auto;

      margin: 40px auto 0;
    }
  }

  &__recent {
    margin: 40px 0 0;

    @include respond-to(tablet) {
      margin: 50px 0 0;
    }

    @include respond-to(macbook) {
      margin: 60px 0 0;
    }

    &-title {
      margin: 0 0 20px;

      @include respond-to(macbook) {
        margin: 0 0 30px;
      }
    }

    &-items {
      @include respond-to(tablet) {
        > :nth-child(3) {
          display: none;
        }
      }

      @include respond-to(macbook) {
        > :nth-child(3) {
          display: block;
        }
      }
    }
  }
}
