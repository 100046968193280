.filter-open {
  @include respond-to(downMacbook) {
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    width: 100vw;
  }
}

.filter-nav {
  display: flex;
  justify-content: center;
  background-color: $color-grey;
  padding: 20px;
  border-bottom: 1px solid $color-border;
  margin: -40px -20px 20px;

  @include respond-to(tablet) {
    margin: -50px -30px 30px;
  }

  @include respond-to(macbook) {
    display: none;
  }

  &__btn {
    &.active {
      .filter-nav__count {
        display: inline-block;
      }
    }
  }

  &__count {
    position: absolute;
    right: 8px;
    top: 6px;
    height: 24px;
    min-width: 24px;
    line-height: 1.7;
    color: $color-main;
    text-align: center;
    background-color: $color-white;
    border-radius: 50%;
    padding: 0 2px;

    display: none;
  }
}

.filter-shown {
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: $color-placeholder;
  margin: 0 0 20px;

  @include respond-to(tablet) {
    margin: 0 0 30px;
  }

  @include respond-to(macbook) {
    display: none;
  }
}

.filter {
  width: 100%;
  right: -100%;

  @include respond-to(downMacbook) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: auto;

    background-color: $color-white;
    transition: right 0.25s ease-out;
    overflow-y: auto;
    z-index: 100;

    .filter-open & {
      right: 0;
    }
  }

  @include respond-to(tablet) {
    width: 394px;
    right: -450px;
    box-shadow: -3px 0 5px 2px rgba($color-black, 0.3);
  }

  @include respond-to(macbook) {
    width: 100%;
    right: 0;
    background-color: $color-grey;
    border-radius: 10px;
    padding: 0;
    margin: 0 0 40px;
    box-shadow: none;
  }

  .ant-form-item {
    margin: 0;
  }

  &__backdrop {
    display: none;
    position: fixed;
    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    @include respond-to(downMacbook) {
      .filter-open & {
        display: block;
      }
    }
  }

  &__btn {
    position: relative;
    width: 50%;
    min-width: auto;
    max-width: 182px;
    padding-left: 10px;
    padding-right: 10px;

    .filter__wrap &:last-child {
      margin: 0 0 0 40px;

      @include respond-to(tablet) {
        margin: 0 0 0 60px;
      }

      &::before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $color-border;

        @include respond-to(tablet) {
          left: -30px;
        }
      }
    }
  }

  &__header {
    display: flex;
    font-size: 24px;
    line-height: 1.34;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $color-grey;
    padding: 20px;

    @include respond-to(tablet) {
      font-size: 40px;
      line-height: 1;
      padding: 30px;
    }

    @include respond-to(macbook) {
      display: none;
    }

    &-title {
      display: inline-block;
      margin: 4px 20px 0 0;

      @include respond-to(tablet) {
        margin: 0 20px 0 0;
      }

      .filter-search-open &:not(.filter__header-search) {
        display: none;
      }
    }

    &-search {
      display: none;

      .filter-search-open & {
        display: inline-block;
      }
    }
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 0 0 0 auto;

    svg,
    span {
      width: 35px;
      height: 35px;
    }

    svg {
      fill: $color-white;
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    padding: 30px 20px 10px;

    @include respond-to(tablet) {
      padding: 30px 30px 10px;
    }

    @include respond-to(macbook) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: min-content;
      grid-column-gap: 30px;
      grid-row-gap: 20px;
      padding: 25px;
      min-height: 0;
      overflow: visible;
    }

    @include respond-to(desktop) {
      grid-template-columns: repeat(3, minmax(100px, 250px)) 1fr;
    }
  }

  &__row {
    margin: 0 0 30px;

    @include respond-to(macbook) {
      margin: 0;
    }

    @include respond-to(downMacbook) {
      .filter-search-open &:not(.filter__search):not(.filter-footer) {
        display: none;
      }
    }
  }

  &__search {
    position: relative;

    @include respond-to(downMacbook) {
      display: none;
    }

    @include respond-to(macbook) {
      grid-column-start: span 3;
      min-height: 0;
    }

    @include respond-to(desktop) {
      grid-column-start: span 4;
    }

    @include respond-to(downMacbook) {
      .filter-search-open & {
        display: block;
      }
    }
  }

  &__item {
    .ant-select.ant-select {
      &:not(.ant-select-disabled):not(.ant-select-customize-input) {
        .ant-select-selector {
          border-color: $color-main;
        }
      }

      &.ant-select-focused,
      &.ant-select-open {
        .ant-select-selector {
          border-width: 2px !important;
        }
      }

      .ant-select-selector {
        border: 2px solid $color-main;

        &:hover {
          border-width: 2px !important;
        }
      }

      .ant-select-arrow {
        background: $color-main;
      }

      .ant-select-selection-search {
        display: none;
      }
    }
  }

  &__select-dropdown.ant-select-dropdown {
    border: 2px solid $color-main;
    padding: 5px 6px;

    .ant-select-item-option {
      display: flex;
      font-size: 16px;
      line-height: 1.3;
      font-weight: 500;
      background-color: $color-grey;
      border-radius: 4px;
      height: auto;
      padding: 6px;
      margin: 0 0 5px;

      &.ant-select-item-option-active,
      &.ant-select-item-option-selected {
        color: $color-white;

        .filter-option__count {
          color: $color-white;
        }
      }

      &.ant-select-item-option-active {
        font-weight: 500;
        background: $color-main-hover;
      }

      &.ant-select-item-option-selected {
        background-color: $color-main;

        .ant-select-item-option-state {
          display: none;
        }
      }

      &:last-child {
        border-radius: 4px;
        margin: 0;
        height: auto;
      }
    }

    .ant-select-item-option-content {
      white-space: initial;
    }
  }

  &-option {
    &__wrap {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column-gap: 5px;
      min-width: 0;
    }

    &__text {
      overflow: hidden;

      .select2-dropdown & {
        white-space: normal;
      }
    }

    &__count {
      position: relative;
      color: $color-placeholder;
      z-index: 1;
    }
  }

  .ant-select-selection-item-remove {
    display: none;
  }

  .ant-select-selection-item {
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    padding: 0;
    border: 0;
    margin-top: 3px;

    @include respond-to(tablet) {
      font-size: 14px;
    }
  }

  & .ant-select.ant-select .ant-select-selection-item {
    padding-right: 0;
  }
}

.filter-footer {
  text-align: center;
  background-color: $color-grey;
  border-top: 1px solid $color-border;
  padding: 20px;
  margin: auto 0 0 0;

  @include respond-to(tablet) {
    padding: 20px 30px;
  }

  @include respond-to(macbook) {
    display: none;
  }

  &__text {
    font-size: 16px;
    line-height: 1.25;
    color: $color-placeholder;
    margin: 0 0 20px;
  }

  &__nav {
    display: flex;
    justify-content: center;
  }
}

.filter-reset {
  display: none;

  @include respond-to(macbook) {
    display: block;
    grid-row-start: 3;
  }

  @include respond-to(desktop) {
    grid-column-start: 4;
    grid-row-start: auto;
    text-align: right;
  }

  &.disabled {
    color: $color-placeholder;
  }

  &__text {
    font-size: 16px;
    line-height: 1.25;
    margin: 0 0 10px;
  }
}
