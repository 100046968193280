.intro {
  min-height: 70vh;

  @include respond-to(tablet) {
    min-height: 80vh;
  }

  @include respond-to(macbook) {
    height: 100vh;
    min-height: 700px;

    background: $color-white url("../images/main.jpg") no-repeat 50% 50%;
    background-size: cover;
    overflow: hidden;
    position: relative;
  }

  &__video {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      z-index: 1;
    }
  }
}
