.programs {
  border-top: 1px solid $color-border;
  padding-top: 30px;

  @include respond-to(tablet) {
    padding-top: 40px;
  }

  &-feed {
    display: grid;
    grid-gap: 20px;

    @include respond-to(tablet) {
      grid-gap: 32px;
      grid-template-columns: repeat(auto-fill, 338px);
    }

    @include respond-to(macbook) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__notice {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      @include respond-to(tablet) {
        grid-column: 1 / -1;
      }
    }

    &__wrapper {
      @include respond-to(tablet) {
        display: grid;
        grid-column-gap: 32px;
        grid-row-gap: 32px;
        grid-template-columns: repeat(auto-fill, 338px);
      }

      @include respond-to(macbook) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__item {
      margin: 20px 0;

      @include respond-to(tablet) {
        margin: 0;
      }

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.program {
  &__wrap .page__block:last-child {
    margin-bottom: 0;
  }
}
