.dropdown {
  height: 40px;
  width: 229px;

  display: flex;
  align-items: stretch;

  z-index: 5;

  &__backdrop {
    position: fixed;
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: none;

    .dropdown_open & {
      display: block;
    }
  }

  &__value {
    flex-grow: 2;
    padding: 0 13px;
    text-overflow: ellipsis;

    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    color: $color-main;

    border: 2px solid $color-main;
    border-right: none;
    border-radius: 10px 0 0 10px;

    display: flex;
    align-items: center;

    cursor: pointer;
    outline: none;

    transition: border $transition-time ease, color $transition-time ease;

    .dropdown:hover & {
      border-color: $color-main-hover;
      color: $color-main-hover;
    }
  }

  &__button {
    width: 40px;
    background: $color-main;
    border-radius: 0 10px 10px 0;

    cursor: pointer;
    outline: none;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: $color-white;
      width: 22px;
      transition: $global-transition;
      transform: rotate(-90deg);

      .dropdown_open & {
        transform: rotate(90deg);
      }
    }

    .dropdown:hover & {
      background: $color-main-hover;
      transition: background $transition-time ease;
    }
  }

  &__body {
    display: none;

    position: absolute;
    z-index: 10;
    top: 100%;
    left: -2px;
    right: -2px;
    margin: 5px 0 0;
    padding: 4px 0;

    border-radius: 10px;
    border: 2px solid $color-main;
    background: $color-white;

    box-shadow: 0 3px 6px 4px rgba($color-black, 0.16);

    .dropdown_open & {
      display: flex;
      flex-direction: column;
    }
  }

  &__link {
    color: $color-main;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.3;
    text-decoration: none;
    padding: 5px 13px;

    transition: color $transition-time ease;

    &:hover {
      color: $color-main-hover;
    }
  }
}
