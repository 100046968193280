.suit-candidats {
  &__title {
    margin: 0 0 30px;
  }

  &__cards {
    @include respond-to(tablet) {
      width: 490px;
    }

    @include respond-to(macbook) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__card {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    grid-column-gap: 20px;
    margin: 0 0 30px;

    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
      grid-template-columns: 150px 1fr;
      grid-column-gap: 30px;
      margin: 0 0 40px;
    }

    @include respond-to(macbook) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0;
      padding: 0 30px;
    }

    &:last-child {
      margin: 0;
    }

    &-icon {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 2px solid $color-main;

      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-to(tablet) {
        width: 150px;
        height: 150px;
        border-radius: 75px;
      }

      @include respond-to(macbook) {
        margin: 0 0 10px;
      }

      svg {
        width: 40px;
        height: 40px;
        fill: $color-main;

        @include respond-to(tablet) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}
