.how {
  counter-reset: how;

  &__item {
    position: relative;
    padding: 0 0 30px;
    margin: 0 0 30px;
    border-bottom: 1px solid $color-border;
    max-width: $macbook;

    @include respond-to(tablet) {
      display: flex;
      border: none;
      padding: 0 0 0 180px;
      margin: 0 0 40px;
      min-height: 150px;

      flex-direction: column;
      justify-content: center;
    }

    .how_text & {
      display: grid;
      grid-template-columns: 100px auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      grid-template-areas:
        "icon title"
        "text text";

      @include respond-to(tablet) {
        // grid-template-columns: 150px auto;
        // grid-template-rows: auto 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        // grid-template-areas:
        //   "icon title"
        //   "icon text";

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }

    &:last-child {
      margin: 0;
      padding: 0;
      border: none;

      @include respond-to(tablet) {
        padding: 0 0 0 180px;
      }
    }

    &:before {
      counter-increment: how;
      content: counter(how);

      position: absolute;
      left: 120px;
      top: 30px;

      flex-shrink: 0;
      display: flex;
      width: 40px;
      height: 40px;
      background: $color-main;
      color: $color-white;
      border-radius: 6px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1;

      justify-content: center;
      align-items: center;

      .how_text & {
        display: none;
      }

      @include respond-to(tablet) {
        left: 180px;
        top: 0;
      }

      @include respond-to(macbook) {
        position: static;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;

      grid-area: title;
      align-self: center;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
        margin: 0 0 20px;
        text-align: left;
        align-self: flex-start;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;

      @include respond-to(tablet) {
        padding: 55px 0 0;

        font-size: 20px;
        line-height: 1.35;
      }

      @include respond-to(macbook) {
        margin: 15px 0 0;
        padding: 0;
      }

      .how_text & {
        font-size: 14px;
        line-height: 1.85;
        font-weight: 400;

        grid-area: text;

        @include respond-to(tablet) {
          padding: 0;
          margin: 0;
        }
      }
    }

    &-icon {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      border: 2px solid $color-main;
      margin: 0 0 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to(tablet) {
        width: 150px;
        height: 150px;
        flex-shrink: 0;
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
      }

      .how_text & {
        grid-area: icon;
        margin: 0;
      }

      svg {
        height: 43px;
        fill: $color-main;

        @include respond-to(tablet) {
          height: 60px;
        }
      }
    }

    &-decor {
      display: none;

      @include respond-to(desktop) {
        display: block;
        position: absolute;
        top: 75px;
        margin-top: -5px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $color-main;
        left: -20px;

        &:before {
          content: "";
          height: 2px;
          background: $color-main;

          position: absolute;
          right: 10px;
          top: 5px;
          margin-top: -1px;
          width: calc(50vw - 620px);
        }
      }
    }
  }
}
