.style-guide {
  background: $color-main;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-size: 16px;
  color: $color-white;
  font-weight: 700;

  padding: 20px;

  p {
    text-transform: uppercase;
  }

  .btn_outline {
    color: $color-white;
    border-color: $color-white;

    &:hover {
      color: $color-border;
      border-color: $color-border;
    }
  }

  @include respond-to(tablet) {
    padding: 30px;
    font-size: 20px;
    line-height: 1.35;
  }

  @include respond-to(macbook) {
    padding: 20px 10px;
  }

  > * {
    margin: 0 0 15px;

    @include respond-to(tablet) {
      margin: 0 0 20px;
    }

    &:last-child {
      margin: 0;
    }
  }
}
