.faq {
  &_in-block {
    margin: 0;
  }

  &__item {
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid $color-border;

    .faq_advanced & {
      @include respond-to(macbook) {
        min-height: 40px;
        padding: 0 0 27px;

        &_decored {
          padding: 0 0 0 30px;
        }
      }
    }

    &:last-child {
      padding: 0;
      border: none;

      @include respond-to(tablet) {
        margin-bottom: 0;
      }
    }

    &_decored {
      border: none;
      border-radius: 10px;
      box-shadow: 0 0 0 2px $color-main;
      padding: 0 0 0 20px;
      margin: 0 0 20px;

      overflow: hidden;

      @include respond-to(tablet) {
        padding: 0 0 0 30px;
        margin: 0 0 30px;
      }

      @include respond-to(macbook) {
        margin: 0 0 40px;
      }

      &:last-child {
        padding: 0 0 0 20px;
        margin: 0;

        @include respond-to(tablet) {
          padding: 0 0 0 30px;
        }
      }
    }

    &-q {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;

      display: flex;
      justify-content: space-between;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }

      .faq__item_decored & {
        text-transform: uppercase;
        min-height: 60px;
        align-items: center;
      }

      .faq_advanced & {
        @include respond-to(macbook) {
          cursor: pointer;
        }
      }

      &-text {
        padding: 10px 0;

        @include respond-to(tablet) {
          padding: 5px 0;
        }
      }

      &-icon {
        order: 2;
        flex-shrink: 0;
        margin: 0 0 0 20px;
        background: $color-main;
        width: 40px;
        height: 40px;
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        .faq__item_decored & {
          border-radius: 0 6px 6px 0;
          width: 60px;
          height: 60px;

          align-self: flex-start;

          box-shadow: 2px 0 0 0 $color-main;

          @include respond-to(tablet) {
            width: 67px;
            height: 67px;
          }
        }

        .faq__item_decored.faq__item_open & {
          border-radius: 0 6px 6px 10px;
        }

        @include respond-to(tablet) {
          margin: 0 0 0 80px;
        }

        svg {
          width: 30px;
          height: 30px;
          fill: $color-white;
          transform: rotate(-90deg);
          transition: $global-transition;

          .faq__item_decored & {
            @include respond-to(tablet) {
              width: 35px;
              height: 35px;
            }
          }

          .faq__item_open & {
            transform: rotate(90deg);
          }
        }
      }
    }

    &-a {
      margin: 20px 0 0;

      p:last-child {
        margin-bottom: 0;
      }

      .faq_advanced & {
        display: none;
        max-width: 892px;
        margin: 0;
      }

      .faq__item_decored & {
        padding: 0 20px 10px 0;

        @include respond-to(tablet) {
          font-size: 18px;
          line-height: 1.6666;
        }
      }

      .faq__item_open & {
        display: block;
        margin: 20px 0 0;

        @include respond-to(tablet) {
          margin: 20px 120px 0 0;
        }

        @include respond-to(macbook) {
          margin: 12px 120px 0 0;
        }
      }

      .faq__item_decored.faq__item_open & {
        margin: 10px 0 0;
      }
    }
  }
}
