.switch {
  &-style {
    width: 80px;
    height: 40px;
    background-color: $color-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;

    svg {
      width: 28px;
      height: 28px;
      fill: $color-main-hover;
    }

    &__handle {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-main;
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      border: 3px solid $color-white;
      transition: all 0.3s ease-out;

      svg {
        fill: $color-white;
      }

      span:last-child {
        display: none;
      }
    }

    &:not(:disabled) .switch-style__handle {
      &:hover {
        transform: scale(1.1);
      }
    }

    &__item {
      display: flex;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.theme_dark .switch-theme,
.accessibility-open .switch-accessibility {
  .switch-style {
    &__handle {
      left: calc(100% - 34px);

      span:last-child {
        display: block;
      }

      span:first-child {
        display: none;
      }
    }
  }
}
