.participation {
  @include respond-to(desktop) {
    max-width: $layoutWidth;
    width: 100%;
    margin: 0 auto;
  }

  &__title {
    margin: 0 0 30px;
  }

  &__steps {
    counter-reset: section;

    @include respond-to(macbook) {
      display: grid;
      grid-template-columns: 462px 462px;
      grid-column-gap: 50px;
      grid-row-gap: 40px;
    }
  }

  &__step {
    border: 2px solid $color-white;
    border-radius: 10px;

    margin: 0 0 30px;

    counter-increment: participation;

    display: flex;
    flex-direction: column;

    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;

    @include respond-to(tablet) {
      flex-direction: row;
      align-items: center;
      font-size: 20px;
      line-height: 1.35;

      min-height: 114px;
    }

    @include respond-to(macbook) {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }

    p {
      margin: 0;
      padding: 20px;
    }

    &::before {
      content: counter(participation);
      color: $color-main;

      font-size: 30px;
      line-height: 1.3;
      font-weight: 700;
      background: $color-white;

      text-align: center;

      border-radius: 5px 5px 0 0;

      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-to(tablet) {
        height: auto;
        border-radius: 5px 0 0 5px;
        width: 60px;

        flex-shrink: 0;
        align-self: stretch;

        font-size: 36px;
      }
    }
  }
}
