.levels {
  @include respond-to(tablet) {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: min-content;
  }

  @include respond-to(macbook) {
    grid-gap: 30px;
  }

  @include respond-to(desktop) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: $color-grey;
    min-height: 110px;
    padding: 10px;
    margin: 0 0 20px;
    border-radius: 10px;

    transition: all 0.1s ease-out;

    @include respond-to(tablet) {
      min-height: 120px;
      margin: 0;
    }

    @include respond-to(macbook) {
      &:hover {
        box-shadow: 0 0 0 2px $color-main;
      }
    }

    &-icon {
      display: flex;
      margin: 0 20px 0 10px;

      @include respond-to(macbook) {
        margin: 0 25px 0 10px;
      }
    }

    svg {
      width: 75px;
      height: auto;
      flex-shrink: 0;
      fill: $color-main;

      @include respond-to(tablet) {
        width: 85px;
      }

      @include respond-to(macbook) {
        width: 95px;
      }
    }

    &-title {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;
      margin: 0;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }
    }
  }
}
