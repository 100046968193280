.calc {
  @include respond-to(tablet) {
    display: grid;
    grid-template-columns: 274fr 104fr 274fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @include respond-to(macbook) {
    grid-template-columns: 500fr 396fr 274fr;
  }

  &__title {
    margin: 0 0 30px;

    @include respond-to(tablet) {
      grid-column: 1 / 4;
      margin: 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;

    margin: 0 0 20px;

    @include respond-to(tablet) {
      grid-column: 1 / 4;
      margin: 0;
    }
  }

  &__block {
    margin: 20px 0;
    display: flex;
    flex-direction: column;

    @include respond-to(tablet) {
      margin: 0;
      align-self: stretch;
    }

    &_slider {
      @include respond-to(tablet) {
        grid-column: 1 / 3;
      }
    }

    &-title {
      background: $color-main;
      border-radius: 10px 10px 0 0;
      height: 50px;

      color: $color-white;
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-shrink: 0;
    }

    &-body {
      border: 2px solid $color-main;
      border-top: none;
      border-radius: 0 0 10px 10px;

      flex-grow: 2;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-value {
      font-size: 30px;
      line-height: 1.3;
      font-weight: 700;
      text-align: center;
      padding: 20px 0;

      @include respond-to(macbook) {
        font-size: 36px;
      }

      &_result {
        color: $color-green;
      }

      &_zero {
        color: $color-notice;
      }

      &_spend {
        @include respond-to(macbook) {
          display: none;
        }
      }
    }
  }

  &__info {
    display: inline-flex;
    flex-direction: column;
    border-radius: 10px;
    border: 2px solid $color-main;
    box-shadow: 3px 3px 9px 0 rgba($color-black, 0.3);
    padding: 10px 10px 10px 50px;
    margin: 10px 0 0;

    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;

    position: relative;

    @include respond-to(tablet) {
      margin: 0;
    }

    @include respond-to(macbook) {
      border-radius: 40px;
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;

      font-size: 20px;
      line-height: 1.35;
      padding: 0 20px;
      white-space: nowrap;
    }

    &-icon {
      position: absolute;
      left: 0;
      top: 0;
      background: $color-main;
      width: 40px;
      height: 40px;
      border-radius: 6px 0 10px 0;

      svg {
        width: 40px;
        height: 40px;
        fill: $color-white;
      }

      @include respond-to(macbook) {
        border-radius: 20px;
        position: static;
        flex-shrink: 0;
        margin: 0 20px 0 0;
      }
    }

    span:not(.anticon) {
      display: block;

      @include respond-to(macbook) {
        display: inline-flex;
        margin: 0 0 0 3px;
        white-space: nowrap;
      }
    }
  }

  &__source {
    margin: 0 0 30px 20px;

    @include respond-to(macbook) {
      margin: 40px 0 30px 40px;
      height: 75px;
    }

    &-x {
      height: 2px;
      background: $color-main-disabled;

      position: relative;

      @include respond-to(macbook) {
        background: $color-main;
      }
    }

    &-value {
      width: 40px;
      height: 40px;
      top: -19px;
      background: $color-main;
      border-radius: 20px;

      position: absolute;
      z-index: 2;
      left: 0;
      transform: translate(-15px, 0);

      transition: left $transition-time ease;

      @include respond-to(macbook) {
        width: 32px;
        height: 32px;
        background: $color-white;
        border: 2px solid $color-main;
        top: -15px;
        transform: translate(-11px, 0);

        &:after {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background: $color-main;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      svg {
        width: 40px;
        height: 40px;
        fill: $color-white;

        @include respond-to(macbook) {
          display: none;
        }
      }
    }

    &-dot {
      position: absolute;
      width: 10px;
      height: 10px;
      top: -4px;
      background: $color-main-hover;
      border-radius: 5px;

      font-size: 20px;
      line-height: 1.33;
      font-weight: 700;

      cursor: pointer;

      @include respond-to(macbook) {
        text-indent: 0;
        background: $color-main;
      }

      @include respond-to(desktop) {
        font-size: 24px;
      }

      span {
        display: none;
        white-space: nowrap;
        position: absolute;
        transform: translate(-50%, 0);
        top: 40px;
        left: 50%;

        @include respond-to(macbook) {
          display: block;
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 2px;
        top: 2px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $color-main;

        @include respond-to(macbook) {
          display: none;
        }
      }
    }

    &-infinite {
      background: $color-main;
      position: absolute;
      right: 0;
      width: 30px;
      height: 40px;
      border-radius: 20px 0 0 20px;
      top: -21px;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      color: $color-white;
      font-weight: 700;
      font-size: 22px;
      line-height: 1.45;

      @include respond-to(macbook) {
        font-size: 30px;
        line-height: 1.25;
        color: $color-black;
        width: 20%;
        height: 10px;
        background: transparent;
        top: -4px;

        span {
          position: absolute;
          white-space: nowrap;
          position: absolute;
          transform: translate(-50%, 0);
          top: 34px;
          left: 50%;
        }
      }
    }
  }
}
