html body {
  min-height: 100vh;
}

body {
  font-family: $global-font-family;
  font-size: $global-font-size;
  line-height: $global-line-height;

  color: $color-black;
  background: $color-white;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  overflow-y: scroll;

  min-width: 320px;

  &.nav-open,
  &.languages-open,
  &.modal-open {
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    position: relative;
    width: 100vw;
  }
}

div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba($color-white, 0.5);
}

body a {
  color: $color-black;

  &:hover {
    color: $color-main;
  }
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-family: $global-font-family;
  outline: none;
}

button {
  outline: none;
  cursor: pointer;
}

.app__wrapper {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  flex-grow: 2;

  display: flex;
  flex-direction: column;

  padding: 0 $padding;
  background: $color-white;

  @include respond-to(tablet) {
    padding: 0 $tPadding;
  }

  @include respond-to(maxLayoutWidth) {
    padding: 0;
  }
}

.t-h2 {
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.t-h3 {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;

  @include respond-to(tablet) {
    font-size: 20px;
    line-height: 27px;
  }
}

.text-center {
  text-align: center;
  justify-content: center;
}

.theme_dark {
  color: $color-dark-text;
  background: $color-dark-main;

  .header__btn:hover:not(.header__burger) {
    text-decoration: underline;
  }

  .feedback__form.form .ant-input-prefix .anticon {
    svg {
      fill: $color-white;
    }
  }

  .content,
  .footer,
  .header__nav-wrap,
  &.nav-open .header__burger-icon span::after,
  &.nav-open .header__burger-icon span::before,
  .filter-nav,
  .filter__header,
  .filter,
  .filter-footer,
  .stat__table-data table tr td {
    background: $color-dark-main;
  }

  a:not(.btn):not(:hover):not(.post-item__more):not(.dropdown__link):not(.header__btn),
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .page__header-body,
  .page__block_cover_white,
  .filter.form.ant-form,
  .documents__check-form .ant-input,
  .calc__source-infinite,
  .not-found,
  .ant-select.ant-select.ant-select-open .ant-select-selection-item {
    color: $color-dark-text;
  }

  .page__header {
    border-bottom: 1px solid $color-dark-border;

    &:after {
      background: rgba($color-dark-main, 0.75);
    }
  }

  .not-found {
    &:after {
      background: rgba($color-dark-main, 0.75);
    }
  }

  .page__block_cover_light,
  .page__block_cover,
  .page__block_cover_white,
  .page__block_bg {
    &::before {
      display: block;
      background: rgba($color-dark-main, 0.75);
    }
  }

  .page__block_bg {
    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .page__block_cover {
    border-top: 1px solid $color-dark-border;
    border-bottom: 1px solid $color-dark-border;
  }

  .page__block_border {
    border-bottom: 1px solid $color-main;
  }

  @include respond-to(macbook) {
    .footer__nav-wrapper {
      border-bottom: 1px solid $color-main;
    }
  }

  .form .ant-input-affix-wrapper {
    border-color: $color-main;
  }

  .form .ant-input-prefix .anticon svg {
    fill: $color-main;
  }

  .header__buttons,
  .card__header-title,
  .ant-select,
  .header__btn,
  .header__btn:hover,
  .form.feedback__form .ant-input {
    color: $color-white;
  }

  .header__languages-item_value:after {
    border-color: $color-dark-text transparent transparent transparent;
  }

  .levels__item-title,
  .list-description__item {
    color: $color-dark-black;
  }

  .partners__item,
  .levels__item,
  .accreditation__item,
  .how__item-icon,
  .filter__select-dropdown.ant-select-dropdown,
  .installment-info__icon,
  .list-description__item,
  .dropdown__body,
  .suit-candidats__card-icon {
    background-color: $color-dark-main-hover;
  }

  .filter {
    @include respond-to(macbook) {
      background-color: $color-dark-main-hover;
    }
  }

  .slider-description__photo,
  .slider-description__no-photo {
    border-color: $color-dark-text;
  }

  .filter-nav,
  .feedback__form .ant-input-affix-wrapper,
  .feedback__form .ant-input-affix-wrapper:hover,
  .feedback__form textarea.ant-input,
  .feedback__form textarea.ant-input:hover,
  .feedback__form textarea.ant-input:focus {
    border-color: $color-dark-border;
  }

  .footer__copyright {
    background-color: $color-black;
    color: $color-dark-border;

    a {
      color: $color-dark-border !important;
    }
  }

  .filter.form.ant-form,
  .ant-select,
  .ant-select.ant-select.ant-select-open .ant-select-selection-item {
    @include respond-to(macbook) {
      color: $color-dark-black;
    }
  }

  .contacts__item svg {
    fill: $color-dark-text;
  }

  .anticon-close-circle svg {
    fill: $color-white;

    @include respond-to(macbook) {
      fill: $color-dark-border;
    }
  }
}

.space_top {
  margin: 20px 0 0;

  @include respond-to(tablet) {
    margin: 30px 0 0;
  }
}

.blockquote {
  &_right {
    text-align: right;
  }
}
