.stat {
  @include respond-to(desktop) {
    width: 100%;
    max-width: $layoutWidth;
    margin: 0 auto;
  }

  &__title {
    margin: 35px 0 20px;

    @include respond-to(tablet) {
      margin: 40px 0 30px;
    }

    @include respond-to(desktop) {
      margin: 50px 0 30px;
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    border-radius: 0;
    height: 60px;

    @include respond-to(tablet) {
      font-size: 16px;
      padding: 0 30px;
      margin: 10px 0 0;
    }

    @include respond-to(macbook) {
      margin: 0;
    }
  }

  &__table {
    margin: 0 -20px;
    position: relative;

    @include respond-to(tablet) {
      margin: 0 -30px;
    }

    @include respond-to(macbook) {
      margin: 0;
    }

    // &:before {
    //   content: "";
    //   width: 20px;
    //   z-index: 2;

    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   bottom: 0;
    //   background: linear-gradient(to right, rgba($color-grey, 0.6), rgba(#776d6d, 0));

    //   @include respond-to(statTable) {
    //     display: none;
    //   }
    // }

    // &:after {
    //   content: "";
    //   width: 20px;

    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   background: linear-gradient(to left, rgba($color-grey, 0.6), rgba(#776d6d, 0));

    //   @include respond-to(statTable) {
    //     display: none;
    //   }
    // }

    &-backdrop {
      background: rgba($color-black, 0.7);
      position: absolute;
      z-index: 2;
      left: 20px;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 10px 0 0 10px;

      @include respond-to(tablet) {
        left: 30px;
      }

      @include respond-to(statTable) {
        display: none;
      }
    }

    &-icon {
      position: absolute;
      z-index: 3;
      left: 50%;
      top: 50%;
      transform: translate(-20px, -20px);
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: $color-main;

      @include respond-to(statTable) {
        display: none;
      }

      svg {
        fill: $color-white;
        width: 40px;
        height: 40px;
      }
    }

    &-data {
      display: block;
      width: 100%;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      position: relative;

      &-container {
        border-radius: 10px;
        border: 2px solid $color-main;
        width: auto;
        display: inline-flex;

        overflow: hidden;
        margin: 0 20px;

        @include respond-to(tablet) {
          margin: 0 30px;
        }

        @include respond-to(macbook) {
          display: block;
          margin: 0;
        }
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 816px;

        @include respond-to(macbook) {
          width: 100%;
        }

        tr {
          th {
            background: $color-main;
            border-right: 2px solid $color-white;

            font-size: 16px;
            line-height: 1.25;
            color: $color-white;
            font-weight: 700;

            padding: 20px;

            text-align: left;

            &:last-child {
              border-right: none;
            }

            @include respond-to(tablet) {
              font-size: 20px;
              line-height: 1.35;
            }
          }

          td {
            border: 2px solid $color-main;
            background: $color-white;
            padding: 20px;
            vertical-align: center;

            width: 33.3333%;

            &:first-child {
              border-left: none;
            }

            &:last-child {
              border-right: none;
            }
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &-person {
    &__name {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }
    }

    &__date {
      font-size: 14px;
      line-height: 2.15;
      font-weight: 500;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.6666;
      }
    }

    &__loc {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      font-style: italic;
      text-transform: uppercase;

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.27777;
      }
    }

    &__status {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 700;
      text-transform: uppercase;

      display: flex;
      align-items: center;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 1.35;
      }

      &-dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: $color-red;

        flex-shrink: 0;

        margin: 0 10px 0 0;
      }
    }

    &__amount {
      font-size: 30px;
      line-height: 1.3;
      font-weight: 700;
    }
  }
}
