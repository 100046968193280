.documents {
  &__check {
    &-form {
      display: block;
      margin: 20px auto 0;

      @include respond-to(tablet) {
        width: 260px;
      }

      &-result {
        margin: 20px 0 0;

        @include respond-to(tablet) {
          margin: 30px 0 0;
        }
      }
    }
  }

  &__result-title {
    display: inline-block;
    font-weight: 700;
    margin: 0 5px 0 0;
  }
}
