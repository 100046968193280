.partner {
  .page__block:last-child {
    margin-bottom: 0;
  }

  &__inner {
    @include respond-to(tablet) {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 30px;
    }

    @include respond-to(macbook) {
      grid-column-gap: 50px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 334px;
    background-color: $color-grey;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 15px;

    @include respond-to(tablet) {
      width: 216px;
      height: 216px;
      min-height: auto;
      margin: 0;
    }

    @include respond-to(macbook) {
      width: 300px;
      height: 300px;
    }

    img {
      width: auto;
      max-height: 185px;
      max-width: 220px;
      vertical-align: middle;
      filter: grayscale(100%);

      @include respond-to(tablet) {
        max-height: 120px;
        max-width: 142px;
      }

      @include respond-to(macbook) {
        max-height: 166px;
        max-width: 196px;
      }
    }
  }

  &__text {
    margin: 0 0 30px;

    @include respond-to(tablet) {
      margin-top: -3px;
    }
  }

  &__btn-wrap {
    text-align: center;

    @include respond-to(tablet) {
      text-align: left;
    }
  }
}
