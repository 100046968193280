.accessibility {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  max-width: $desktop;
  font-size: 18px;
  line-height: 1.67;
  margin: 0 auto;
  padding: 10px 0;

  @include respond-to(tablet) {
    padding: 0 30px;
  }

  @include respond-to(maxLayoutWidth) {
    padding: 0;
  }

  @include respond-to(macbook) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-col {
    display: flex;
    align-items: baseline;
  }

  &__btn-icon {
    line-height: 0;
    margin: 0 10px 0 0;

    svg {
      fill: $color-black;
      width: 20px;
      height: 20px;
    }
  }

  &__btn {
    border-radius: 3px;
    text-decoration: none;
    display: inline-block;
    padding: 0 5px;
    line-height: 20px;
    margin: 0 8px;

    cursor: pointer;

    @include respond-to(tablet) {
      margin: 0 2px;
    }

    &-label {
      margin: 0 5px 0 0;
    }

    &-back {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &_size2 {
      font-size: 20px !important;
    }

    &_size3 {
      font-size: 22px !important;
    }

    &.active {
      box-shadow: inset 0 2px 2px 0 #666;
      background: #b3b3b3;
    }

    &_color1 {
      background-color: $color-white;
      border: 1px solid $color-black;
    }

    &_color2 {
      background-color: $color-black;
      color: $color-white;
      border: 1px solid $color-black;

      &.active {
        box-shadow: none;
        background-color: $color-black;
        border: 1px solid $color-white;
      }
    }

    &_color3 {
      background-color: #309;
      color: $color-white;
      border: 1px solid $color-black;

      &.active {
        box-shadow: none;
        background-color: #309;
      }
    }
  }
}

body.accessibility {
  &-open {
    .page__block-img,
    .post-item__image-link,
    .post__image {
      display: none;
    }
  }

  &-size2 {
    font-size: 22px;
    line-height: 28px;

    p,
    h3,
    h4,
    .page__block-subtitle,
    .footer__consult-label,
    .footer__socials-title,
    .page__header-intro,
    .page__header-subtitle,
    .header_subtitle,
    .list__item-title,
    .list-ordered__item,
    .wysiwyg,
    .list-cards__item,
    .greeting__post,
    .greeting__name,
    .card__header-title,
    .card__title,
    .card__subtitle,
    .steps__item-label-title,
    .steps__item-label-text,
    .slider-description__name,
    .slider-description__post,
    .slider-description__content,
    .link_decor,
    .collapse__header-text,
    .collapse__content,
    .header__btn-label,
    .t-h3,
    .t-h2,
    .footer__nav-title,
    .footer__nav-item {
      font-size: 22px;
      line-height: 28px;
    }

    .btn,
    .header__languages-item,
    .header__nav-title,
    .header__nav-item,
    .form__label-title,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .list-description__item-text,
    .list-description__item-title,
    .review__post,
    .post__share-label,
    .contacts__item-label,
    .contacts__item-value,
    .stat__table-data table tr th,
    .page__block_text_lg,
    .faq__item-q,
    .tile__item-title,
    .main__about-title,
    .how__item-title,
    .how_text .how__item-text,
    .filter-shown,
    .filter-footer__text,
    .filter-reset__text,
    .info__item {
      font-size: 22px;
    }
  }

  &-size3 {
    font-size: 32px;
    line-height: 40px;

    p,
    h3,
    h4,
    .page__block-subtitle,
    .footer__consult-label,
    .footer__socials-title,
    .page__header-intro,
    .page__header-subtitle,
    .header_subtitle,
    .list__item-title,
    .list-ordered__item,
    .wysiwyg,
    .list-cards__item,
    .greeting__post,
    .greeting__name,
    .card__header-title,
    .card__title,
    .card__subtitle,
    .steps__item-label-title,
    .steps__item-label-text,
    .slider-description__name,
    .slider-description__post,
    .slider-description__content,
    .link_decor,
    .collapse__header-text,
    .collapse__content,
    .header__btn-label,
    .t-h3,
    .t-h2,
    .footer__nav-title,
    .footer__nav-item {
      font-size: 32px;
      line-height: 40px;
    }

    .btn,
    .header__languages-item,
    .header__nav-title,
    .header__nav-item,
    .form__label-title,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .list-description__item-text,
    .list-description__item-title,
    .review__post,
    .post__share-label,
    .contacts__item-label,
    .contacts__item-value,
    .stat__table-data table tr th,
    .page__block_text_lg,
    .faq__item-q,
    .tile__item-title,
    .main__about-title,
    .how__item-title,
    .how_text .how__item-text,
    .filter-shown,
    .filter-footer__text,
    .filter-reset__text,
    .info__item {
      font-size: 32px;
    }
  }

  &-size2,
  &-size3 {
    .card__more {
      height: auto;
    }

    .header__languages-item_value:after,
    &.languages-open .header__languages-item_active:after {
      display: none;
    }

    .info_counter .info__item:before,
    .info__item-label-title,
    .info_counter .info__item-label,
    .post-item__title,
    .post-item__date,
    .post-item__more {
      font-size: inherit;
    }

    .how__item {
      grid-template-areas:
        "icon icon"
        "title title"
        "text text";
    }
  }

  &-color1,
  &-color2,
  &-color3 {
    .table-striped > tbody > tr:nth-of-type(odd),
    .list-cards__item,
    .greeting,
    .steps__item:before,
    .card__header::after,
    .card__header:hover::after,
    .filter-nav,
    .list-description__item,
    .filter-nav__count {
      background-color: transparent;
    }

    .page__block-img,
    .card__header img,
    .steps__item-decor,
    .page__block-cover,
    .info__item-decor,
    .how__item-decor {
      display: none;
    }

    &.languages-open .header__languages-item {
      border: none;
    }

    .accreditation__item {
      filter: grayscale(100%);
    }

    .header__search-field .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .card__header::after {
      border: 2px solid;
      border-radius: 10px 10px 0 0;
    }

    .filter-nav__count {
      position: relative;
      line-height: inherit;
    }

    .filter-shown,
    .filter-footer__text,
    .filter-reset__text {
      color: inherit;
    }
  }

  &-color1 {
    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item,
    .btn_primary,
    .form__search-field.ant-input-search .ant-input-affix-wrapper {
      color: $color-black;
      border-color: $color-black;
    }

    svg,
    .switch-style__item svg {
      fill: $color-black !important;
    }

    button.gsc-search-button,
    .header__btn-icon,
    .switch__handle,
    .faq__item-q-icon,
    .videos__img-icon,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .collapse__header-icon {
      svg {
        fill: $color-white !important;
      }
    }

    .how__item-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
    .filter__select-dropdown.ant-select-dropdown,
    .card__header::after,
    .installment-info__icon,
    .widget-btn__hint,
    .stat__table-data table tr td,
    .stat__table-data-container {
      border-color: $color-black;
    }

    .gsc-input-box {
      border-color: $color-black !important;
    }

    header,
    .header__btn:hover,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel,
    .header__nav-wrap,
    .wysiwyg ul li:before,
    .gsst_a .gscb_a,
    .gcsc-find-more-on-google,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option .filter-option__count,
    .card__header-title,
    .filter-nav__count,
    .career-stat__num {
      color: $color-black;
    }

    .header__logo-icon,
    .levels__item {
      svg > path,
      rect {
        fill: $color-black;
      }
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright,
    .header__nav-wrap,
    .footer,
    .btn_primary,
    .header__burger-icon span::after,
    .header__burger-icon span::before,
    .ant-carousel .slick-arrow:hover,
    .slider-arrow:hover,
    .filter,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option {
      background-color: $color-white;
    }

    .ya-share2__badge {
      background-color: $color-black !important;
    }

    .title_decor::before,
    .slider-description__content:before,
    .link_decor::before,
    .collapse__header-icon,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button,
    .header__btn-icon,
    .switch-style__handle,
    &.languages-open .header__languages-wrapper,
    .info_counter .info__item:before,
    .faq__item-q-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select .ant-select-arrow,
    .list-description__item-title::before,
    .ant-spin .ant-spin-dot-item,
    .review__header::before,
    .stat__table-data table tr th {
      background-color: $color-black;
    }

    .collapse__item,
    .levels__item:hover,
    .faq__item_decored {
      box-shadow: 0 0 0 2px $color-black;
    }
  }

  &-color2 {
    color: $color-white;

    .ant-layout,
    .content {
      background-color: $color-black;
    }

    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item,
    .btn_primary,
    .form__search-field.ant-input-search .ant-input-affix-wrapper {
      color: $color-white;
      border-color: $color-white;
    }

    svg {
      fill: $color-white !important;
    }

    button.gsc-search-button,
    .header__btn-icon,
    .switch__handle,
    .faq__item-q-icon,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .collapse__header-icon,
    .switch-style__item,
    .installment-info__icon,
    .footer__nav-apps-link {
      svg {
        fill: $color-black !important;
      }
    }

    .how__item-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
    .filter__select-dropdown.ant-select-dropdown,
    .card__header::after,
    .installment-info__icon,
    .widget-btn__hint,
    .stat__table-data table tr td,
    .stat__table-data-container,
    .form .ant-input-affix-wrapper,
    .form textarea.ant-input {
      border-color: $color-white;
    }

    .gsc-input-box {
      border-color: $color-white !important;
    }

    .ant-select.ant-select .ant-select-arrow:after {
      border-color: $color-black;
    }

    header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .page__header-body,
    .header__btn:hover,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel,
    .header__nav-wrap,
    .wysiwyg ul li:before,
    .gsst_a .gscb_a,
    .gcsc-find-more-on-google,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option .filter-option__count,
    .career-stat__num,
    .filter__search .ant-input,
    .ant-select,
    .documents__check-form .ant-input,
    .filter-nav__count,
    .form__label-title {
      color: $color-white;
    }

    .header__logo-icon,
    .levels__item {
      svg > path,
      rect {
        fill: $color-white;
      }
    }

    .header__logo-icon svg > g > path {
      fill: $color-black;
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright,
    .header__nav-wrap,
    .footer,
    .btn_primary,
    .header__burger-icon span,
    .header__burger-icon span::after,
    .header__burger-icon span::before,
    .ant-carousel .slick-arrow:hover,
    .slider-arrow:hover,
    .filter,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .levels__item,
    .info_counter .info__item:before,
    .stat__table-data table tr th,
    .stat__table-data table tr td,
    .link_decor::before,
    &.languages-open .header__languages-wrapper {
      background-color: $color-black;
    }

    .ya-share2__badge {
      background-color: $color-black !important;
    }

    .title_decor::before,
    .slider-description__content:before,
    .collapse__header-icon,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button,
    .header__btn-icon,
    .switch-style__handle,
    .faq__item-q-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select .ant-select-arrow,
    .list-description__item-title::before,
    .ant-spin .ant-spin-dot-item,
    .review__header::before,
    &.nav-open .header__burger-icon span {
      background-color: $color-white;
    }

    .accessibility__btn_color1,
    .tile__item-pic-download-label {
      color: $color-black;
    }

    .collapse__item,
    .levels__item:hover,
    .faq__item_decored {
      box-shadow: 0 0 0 2px $color-white;
    }
  }

  &-color3 {
    color: #063462;

    .ant-layout,
    .content {
      background-color: #9dd1ff;
    }

    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item,
    .btn_primary,
    .form__search-field.ant-input-search .ant-input-affix-wrapper {
      color: #063462;
      border-color: #063462;
    }

    svg {
      fill: #063462 !important;
    }

    button.gsc-search-button,
    .header__btn-icon,
    .switch__handle,
    .faq__item-q-icon,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .collapse__header-icon,
    .switch-style__item,
    .installment-info__icon {
      svg {
        fill: #9dd1ff !important;
      }
    }

    .footer__nav-apps-link {
      svg {
        fill: $color-black !important;
      }
    }

    .how__item-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
    .filter__select-dropdown.ant-select-dropdown,
    .card__header::after,
    .installment-info__icon,
    .widget-btn__hint,
    .stat__table-data table tr td,
    .stat__table-data-container,
    .form .ant-input-affix-wrapper,
    .form textarea.ant-input {
      border-color: #063462;
    }

    .gsc-input-box {
      border-color: #063462 !important;
    }

    .ant-select.ant-select .ant-select-arrow:after {
      border-color: #9dd1ff;
    }

    header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .page__header-body,
    .header__btn:hover,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel,
    .header__nav-wrap,
    .wysiwyg ul li:before,
    .gsst_a .gscb_a,
    .gcsc-find-more-on-google,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option .filter-option__count,
    .career-stat__num,
    .filter__search .ant-input,
    .ant-select,
    .documents__check-form .ant-input,
    .info_counter .info__item:before,
    .stat__table-data table tr th,
    .filter-nav__count {
      color: #063462;
    }

    .header__logo-icon,
    .levels__item {
      svg > path,
      rect {
        fill: #063462;
      }
    }

    .header__logo-icon svg > g > path {
      fill: #9dd1ff;
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright,
    .header__nav-wrap,
    .footer,
    .btn_primary,
    .header__burger-icon span,
    .header__burger-icon span::after,
    .header__burger-icon span::before,
    .ant-carousel .slick-arrow:hover,
    .slider-arrow:hover,
    .filter,
    .filter__select-dropdown.ant-select-dropdown .ant-select-item-option,
    .levels__item,
    .info_counter .info__item:before,
    .stat__table-data table tr th,
    .stat__table-data table tr td,
    .link_decor::before,
    .header__search-field.ant-input-search .ant-input-affix-wrapper {
      background-color: #9dd1ff;
    }

    .ya-share2__badge {
      background-color: #9dd1ff !important;
    }

    .title_decor::before,
    .slider-description__content:before,
    .collapse__header-icon,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button,
    .header__btn-icon,
    .switch-style__handle,
    &.languages-open .header__languages-wrapper,
    .faq__item-q-icon,
    button.gsc-search-button,
    .form__search-field.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button,
    .filter__item .ant-select.ant-select .ant-select-arrow,
    .list-description__item-title::before,
    .ant-spin .ant-spin-dot-item,
    .review__header::before,
    &.nav-open .header__burger-icon span {
      background-color: #063462;
    }

    .accessibility__btn_color1,
    .tile__item-pic-download-label {
      color: #9dd1ff;
    }

    .collapse__item,
    .levels__item:hover,
    .faq__item_decored {
      box-shadow: 0 0 0 2px #063462;
    }

    .accessibility__btn_color1 {
      color: $color-black;
    }
  }

  .accessibility-panel {
    font-size: 18px;
  }
}
