.auth-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  position: relative;
  z-index: 2;

  &__title {
    font-size: 24px;
    line-height: 1.35;
    font-weight: 700;
    text-transform: uppercase;

    @include respond-to(tablet) {
      font-size: 40px;
      line-height: 1.3;
    }

    @include respond-to(macbook) {
      width: 900px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    text-transform: uppercase;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
    }

    @include respond-to(macbook) {
      width: 700px;
    }
  }

  > * {
    margin: 0 0 30px;

    @include respond-to(tablet) {
      margin: 0 0 40px;
    }

    &:last-child {
      margin: 0;
    }
  }
}
