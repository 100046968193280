.greeting {
  background-color: $color-grey;

  &::before {
    display: none;
  }

  &__img-cover {
    display: none;

    @include respond-to(macbook) {
      display: block;
    }
  }

  &__body {
    display: grid;
    grid-row-gap: 30px;
    position: relative;

    @include respond-to(tablet) {
      grid-column-gap: 40px;
      grid-template-columns: 200px 1fr;
    }

    @include respond-to(macbook) {
      grid-column-gap: 70px;
      grid-template-columns: 274px 1fr;
    }
  }

  &__photo {
    display: block;
    border-radius: 50%;
    max-height: 200px;
    margin: 0 auto 20px;

    @include respond-to(tablet) {
      width: 100%;
      max-height: none;
    }
  }

  &__post {
    line-height: 1.25;
    text-align: center;
    font-style: italic;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      font-size: 16px;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 1.35;
    font-weight: 700;
    text-align: center;

    @include respond-to(tablet) {
      font-size: 20px;
    }
  }

  &__title-top {
    text-align: center;

    @include respond-to(tablet) {
      display: none;
    }
  }

  &__title {
    display: none;

    @include respond-to(tablet) {
      display: block;
    }
  }

  &__text {
    max-width: 650px;
  }
}
