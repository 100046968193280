.alumni {
  margin: 30px 0 0;

  @include respond-to(macbook) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;
    text-align: center;
    margin: 40px 0 0;
  }

  &__item {
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: 100px auto;
    margin: 0 0 30px;

    @include respond-to(tablet) {
      grid-template-columns: 150px auto;
      grid-column-gap: 30px;
    }

    @include respond-to(macbook) {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__item-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(macbook) {
      justify-content: flex-start;
    }
  }

  &__item-photo {
    width: 100px;
    height: 100px;
    background-color: $color-white;
    border-radius: 50%;
    border: 2px solid $color-main;
    overflow: hidden;

    @include respond-to(tablet) {
      width: 150px;
      height: 150px;
    }

    @include respond-to(macbook) {
      margin: 0 auto;
    }

    img {
      height: 100%;
      object-fit: cover;
      vertical-align: middle;
      width: 100%;
    }
  }

  &__item-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 27px;
      margin: 0 0 15px;
    }

    @include respond-to(macbook) {
      margin: 0 0 10px;
    }
  }
}
