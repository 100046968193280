$color-black: #000000;
$color-white: #FFFFFF;
$color-white-hover: #D0E2FF;
$color-main: #448AFF;
$color-main-hover: #6AA0FF;
$color-main-disabled: #D0E2FF;
$color-main-active: #276DE6;
$color-placeholder: #999999;
$color-border: #E0E0E0;

$color-green: #43A047;
$color-yellow: #F9A825;
$color-red: #D52B1E;
$color-blue: #448aff;
$color-grey: #F5F5F5;
$color-notice: #D52B1E;

$color-dark-main: #1f2836;
$color-dark-main-hover: #D0E2FF;
$color-dark-black: #212121;
$color-dark-text: #E0E0E0;
$color-dark-border: #999999;

$global-font-family: 'IBM Plex Sans', sans-serif;
$font-family-num: 'Roboto', sans-serif;
$global-font-size: 14px;
$global-line-height: 1.36;

$global-transition: all 0.2s ease-out;
$transition-time: 0.25s;

$buttonWidth: 182px;
$headerHeight: 80px;
$padding: 20px;
$tPadding: 30px;

$small: 374px;
$crop: 500px;
$tablet: 768px;
$macbook: 1075px;
$downMacbook: 1074.98px;
$desktop: 1200px;
$layoutWidth: 1200px;
$maxLayoutWidth: 1220px;
$statTableWidth: 816px;

// vshp/ui components colors
$ui-color-primary: $color-main;
$ui-color-primary-hover: $color-main-hover;
$ui-switch: $color-white-hover;
