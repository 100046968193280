.ant-select.ant-select {
  &.ant-select-open {
    .ant-select-selector {
      border-width: 1px !important;

      &:hover {
        border-width: 1px !important;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      border-width: 1px !important;

      &:hover {
        border-width: 1px !important;
      }
    }
  }

  .ant-select-selector {
    height: 40px;
    border-radius: 10px;
    border: 1px solid $color-black;
    overflow: hidden;
    padding: 0 45px 0 12px;
    background: transparent;

    &:hover {
      border-width: 1px !important;
    }

    .has-error & {
      border-color: $color-black;
      border-width: 1px !important;
    }
  }

  .ant-select-selection-placeholder {
    color: $color-placeholder;
    font-size: 16px;
    padding-top: 3px;
    font-weight: 700;
  }

  .ant-select-selection__rendered {
    line-height: 36px;
    margin: 0 38px 0 10px;

    display: flex;
    align-items: center;
  }

  .ant-select-selection-search {
    right: 40px;

    &-input {
      height: 38px !important;
    }
  }

  .ant-select-selection-selected-value {
    color: $color-black;
    padding-right: 10px;
  }

  .ant-select-selection-item {
    padding-right: 27px;
  }

  .ant-select-arrow {
    top: 1px;
    right: 0;
    width: 38px;
    height: 38px;
    margin: 0;
    box-sizing: border-box;
    border-left: none;
    background: $color-black;
    outline: none;
    border-radius: 0 8px 8px 0;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -3px;
      width: 7px;
      height: 7px;
      box-sizing: border-box;
      border: 2px solid $color-white;
      border-left: none;
      border-top: none;
      transform: rotate(45deg);
    }

    .anticon {
      display: none;
    }
  }

  &.ant-select-open {
    .ant-select-selector {
      box-shadow: none;
      border-radius: 10px;
    }

    .ant-select-arrow {
      &:after {
        transform: rotate(-45deg) scale(1, -1);
        margin: -2px 0 0 -3px;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none;
    }
  }

  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-color: $color-black;
      }
    }

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        box-shadow: none;
        border-color: $color-black;
      }
    }
  }
}

.ant-select-dropdown.ant-select-dropdown {
  box-shadow: 0 3px 6px 0 rgba($color-black, 0.16);
  border-radius: 10px;
  border: 1px solid $color-black;
  background: $color-white;

  overflow: hidden;

  &.ant-select-dropdown-placement-topLeft {
    border: 1px solid $color-black;
    border-bottom: none;
  }

  .ant-select-dropdown-menu {
    padding: 0;
  }

  .ant-select-item-option {
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    white-space: nowrap;
    box-sizing: border-box;

    &:last-child {
      border-radius: 0 0 8px 8px;
      border: none;
      height: 38px;
    }

    &.ant-select-item-option-active {
      background: none;
      font-weight: normal;
    }
  }
}

.select-country-item {
  display: flex;
  align-items: center;
  height: 28px;

  font-size: 16px;
  line-height: 18px;
  font-weight: 600 !important;

  .ant-select-selection-item & {
    height: 38px;
  }

  @include respond-to(tablet) {
    font-size: 14px;
  }

  .ant-select-selection__rendered & {
    padding: 0 0 0 2px;
  }

  &__image {
    margin: 0 10px 0 0;
    width: 16px;

    img {
      display: block;
      width: 16px;
      margin: 0;
    }
  }

  &__name {
    margin: 0 5px 0 0;
    color: $color-black;
  }

  &__code {
    color: $color-placeholder;
  }
}

.ant-select.ant-select.ant-select_main {
  .ant-select-selector {
    border-color: $color-main;

    .has-error & {
      border-color: $color-main;
      border-width: 1px !important;
    }
  }

  .ant-select-arrow {
    background: $color-main;
  }

  .ant-select-selector__placeholder {
    color: $color-main;
    font-weight: 700;
  }

  .ant-select-selector-selected-value {
    color: $color-main;
    font-weight: 700;
  }
}

.ant-select-dropdown.ant-select-dropdown.ant-select-dropdown_blue {
  border: 1px solid $color-main;
  min-height: 0;

  .ant-select-dropdown-menu {
    padding: 4px 0;
  }

  .ant-select-dropdown-menu-item {
    font-weight: 700;
    color: $color-main;
    height: 28px !important;

    transition: color 0.25s ease;

    &:hover {
      color: $color-main-hover;
    }
  }
}

.ant-select.ant-select-multiple .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 0 1px;
}
