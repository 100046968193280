.page {
  &__header {
    margin: 0 -1 * $padding;
    border-bottom: 1px solid $color-border;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    padding: 100px 0 40px;

    color: $color-black;

    overflow: hidden;

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
      padding: 120px 0 50px;
    }

    @include respond-to(macbook) {
      padding: 145px 0 60px;
    }

    @include respond-to(maxLayoutWidth) {
      margin: 0;
    }

    &_block {
      min-height: 0;
      margin-top: 20px;
      padding: 40px $padding;
      border-top: 1px solid $color-border;
      border-bottom: none;

      @include respond-to(tablet) {
        margin-top: 25px;
        padding: 50px $tPadding;
      }

      @include respond-to(desktop) {
        margin-top: 30px;
        padding: 60px 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($color-white, 0.85);
    }

    &-cover {
      position: absolute;
      z-index: 1;

      object-fit: cover;

      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-body {
      position: relative;
      z-index: 3;
      padding: 0 $padding;
      color: $color-black;

      &_center {
        text-align: center;

        .page__header-title {
          margin: 0 auto;
        }
      }

      @include respond-to(tablet) {
        padding: 0 $tPadding;
      }

      @include respond-to(desktop) {
        width: $layoutWidth;
        margin: 0 auto;
        padding: 0;
      }
    }

    &-title {
      margin: 0;

      &_center {
        text-align: center;
        margin: 0 auto;
      }

      @include respond-to(macbook) {
        max-width: 892px;
      }

      .page__header_plan & {
        @include respond-to(desktop) {
          text-align: center;
        }
      }
    }

    &-subtitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin: 10px 0 0;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
      }

      @include respond-to(macbook) {
        margin: 30px 0 0;
      }
    }

    &-intro {
      font-weight: 500;
      line-height: 1.45;
      margin: 27px 0 0;

      p {
        margin-bottom: 0;
      }

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.67;
      }

      @include respond-to(macbook) {
        width: 892px;

        .page__header-body_center & {
          margin: 27px auto 0;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0 0;

      @include respond-to(tablet) {
        margin: 30px 0 0;
        flex-direction: row;
        justify-content: center;
      }

      .btn {
        margin: 10px 0;

        @include respond-to(tablet) {
          margin: 0 10px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-payments {
      @include respond-to(tablet) {
        min-width: 318px;
      }
    }
  }

  &__intro {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      font-size: 18px;
      line-height: 30px;
      margin: 0 0 20px;
    }

    @include respond-to(macbook) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      max-width: 892px;

      &_left {
        text-align: left;
        max-width: 100%;
      }
    }

    &_center {
      text-align: center;
    }
  }

  &__title {
    margin: 0 0 30px;

    @include respond-to(tablet) {
      margin: 0 0 40px;
    }
  }

  &__text_big {
    @include respond-to(tablet) {
      font-size: 18px;
      line-height: 1.7;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__block {
    @include respond-to(desktop) {
      width: 100%;
      max-width: $layoutWidth;
      margin: 0 auto;
    }

    .page__blocks & {
      margin: 0 auto 40px;

      @include respond-to(tablet) {
        margin: 0 0 50px;
      }

      @include respond-to(desktop) {
        margin: 0 auto 60px;
      }

      &:first-child:not(.page__block_cover) {
        margin: 40px 0;

        @include respond-to(tablet) {
          margin: 50px 0;
        }

        @include respond-to(desktop) {
          margin: 60px auto;
        }
      }
    }

    &_cover {
      position: relative;
      margin: 0 -20px;
      padding: 40px 20px;
      color: $color-white;

      &:not(.page__block_cover_white) {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $color-white;
        }
      }

      @include respond-to(tablet) {
        padding: 50px 30px;
        margin: 0 -30px;
      }

      @include respond-to(desktop) {
        width: auto;
      }

      @include respond-to(maxLayoutWidth) {
        margin: 0;
        padding: 60px 50px;
        max-width: 100%;
      }

      .page__blocks & {
        margin: 0 -20px 40px;

        @include respond-to(tablet) {
          margin: 0 -30px 50px;
        }

        @include respond-to(maxLayoutWidth) {
          margin: 0 0 60px;
        }
      }

      &.disciplines {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.7);
      }

      > * {
        position: relative;
        z-index: 3;
      }

      &_light {
        &::before {
          background: rgba($color-black, 0.5);
        }
      }

      &_white {
        color: $color-black;

        &::before {
          background: rgba($color-white, 0.9);
        }
      }
    }

    &_border {
      border-bottom: 1px solid $color-border;
      padding: 0 0 40px;
      margin: 0 0 40px;

      @include respond-to(tablet) {
        padding: 0 0 50px;
        margin: 0 0 50px;
      }

      @include respond-to(desktop) {
        padding: 0 0 60px;
        margin: 0 auto 60px;
      }

      &_top {
        border-top: 1px solid $color-border;
        padding: 40px 0 0;

        @include respond-to(tablet) {
          padding: 50px 0 0;
        }

        @include respond-to(desktop) {
          padding: 60px 0 0;
        }
      }
    }

    &-title {
      margin: 0 0 30px;

      @include respond-to(desktop) {
        margin: 0 0 40px;
      }
    }

    &-subtitle {
      font-weight: 500;
      margin: 0 0 30px;

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.67;
      }
    }

    &-cover {
      position: absolute;
      z-index: 1 !important;
      object-fit: cover;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-text {
      margin: 0 0 20px;

      @include respond-to(tablet) {
        margin: 0 0 30px;
      }
    }

    &_bg {
      background: $color-grey;
      margin: 0 -20px;
      padding: 40px 20px;

      @include respond-to(tablet) {
        padding: 50px 30px;
        margin: 0 -30px;
      }

      @include respond-to(desktop) {
        margin: 0;
        padding: 60px 50px;
        max-width: 100%;
      }

      .page__blocks & {
        margin: 0 -20px 40px;

        @include respond-to(tablet) {
          margin: 0 -30px 50px;
        }

        @include respond-to(desktop) {
          margin: 0 0 60px;
        }
      }
    }

    &-body {
      max-width: $layoutWidth;

      &-text {
        font-size: 16px;
        line-height: 1.375;
        font-weight: 700;

        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 1.3;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-body_small {
      max-width: 892px;
    }

    &-body_center {
      margin-left: auto;
      margin-right: auto;
    }

    &_auth {
      position: relative;

      .page__block-cover {
        display: none;

        @include respond-to(tablet) {
          display: block;
        }
      }
    }
  }

  &__back-link {
    @include respond-to(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ant-spin {
    display: block;

    @include respond-to(tablet) {
      grid-column: 1 / -1;
    }
  }
}

.page__header-btn,
.page__block-btn {
  margin: 20px 0 0;

  @include respond-to(tablet) {
    margin: 30px 0 0;
  }
}
