.mission {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 360px;

  @include respond-to(tablet) {
    min-height: 400px;
  }
}
