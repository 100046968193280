.notice {
  background: $color-blue;
  position: relative;
  z-index: 101;

  padding: 20px;

  @include respond-to(tablet) {
    padding: 30px;
  }

  @include respond-to(desktop) {
    padding: 40px 0;
  }

  &_white {
    background: $color-white;
    box-shadow: -1px 0 6px 0 rgba($color-black, 0.16);
  }

  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  &_bottom {
    top: auto;
    bottom: 0;
  }

  &__inner {
    display: grid;

    grid-row-gap: 10px;

    position: relative;

    padding: 0 80px 0 0;

    @include respond-to(desktop) {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      grid-column-gap: 0;
    }
  }

  &__info-icon {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: $color-white;

    svg {
      fill: $color-blue;
      height: 40px;
    }

    @include respond-to(desktop) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    font-size: 16px;
    line-height: 20px;
    color: $color-white;

    .notice_white & {
      color: $color-black;
    }

    @include respond-to(desktop) {
      margin: 0 30px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  &__title {
    font-weight: 700;
  }

  &__action {
    margin-right: auto;

    &_white {
      background: $color-white;
      border-color: $color-white;
      color: $color-main;

      &:hover {
        color: $color-main-hover;
      }
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    align-self: center;
    background: $color-white !important;
    display: flex;

    svg {
      fill: $color-main;
    }

    &:hover {
      background: $color-white;

      svg {
        fill: $color-main-hover;
      }
    }

    &:active {
      svg {
        fill: $color-main-active;
      }
    }
  }
}
