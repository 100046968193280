.career-stat {
  @include respond-to(tablet) {
    margin: -5px 0 0;
  }

  @include respond-to(macbook) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }

  &__col {
    margin: 0 0 23px;

    @include respond-to(tablet) {
      margin: 0 0 26px;
    }

    @include respond-to(macbook) {
      margin: 0;
    }
  }

  &__num {
    font-family: $font-family-num;
    font-weight: 900;
    font-size: 80px;
    line-height: 1.33;
    color: $color-blue;

    @include respond-to(tablet) {
      font-size: 140px;
    }
  }

  &__title {
    text-transform: none;
    max-width: 450px;
    margin: -5px 0 0;

    @include respond-to(tablet) {
      margin: -10px 0 0;
    }
  }
}
