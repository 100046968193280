.info {
  @include respond-to(macbook) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &_counter {
    counter-reset: info;

    @include respond-to(macbook) {
      display: block;
    }
  }

  &_space {
    @include respond-to(macbook) {
      padding: 0 36px;
    }
  }

  &__separator {
    display: none;

    @include respond-to(macbook) {
      flex-grow: 2;
      height: 2px;
      background: $color-main;
      margin: 74px 15px 0 15px;

      display: block;
      position: relative;

      &:before,
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $color-main;

        position: absolute;
        left: -5px;
        top: -4px;
      }

      &:after {
        left: auto;
        right: -5px;
      }

      .info_counter & {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    padding: 0 0 0 120px;
    min-height: 180px;

    display: flex;
    flex-direction: column;
    margin: 0 0 10px;

    .info_counter & {
      padding: 0 0 0 60px;
      min-height: 157px;
      margin: 0 0 3px;

      @include respond-to(tablet) {
        min-height: 116px;
        margin: 0 0 4px;
      }

      @include respond-to(small_landscape) {
        min-height: 116px;
        margin: 0 0 4px;
      }
    }

    @include respond-to(tablet) {
      margin: 0 0 30px;
      padding: 0 80px 0 170px;
      min-height: 150px;

      justify-content: center;

      .info_counter & {
        justify-content: flex-start;
      }
    }

    @include respond-to(macbook) {
      width: 150px;
      flex-shrink: 0;
      display: block;
      padding: 0;
      margin: 0;
      text-align: center;

      .info_counter & {
        width: 900px;
        text-align: left;
      }
    }

    &:last-child {
      min-height: 100px;
      margin: 0;

      @include respond-to(tablet) {
        min-height: 150px;
      }

      &:last-child .info__item-label-text {
        padding-bottom: 0;
      }

      .info_counter & {
        min-height: 46px;
      }
    }

    &:before {
      .info_counter & {
        counter-increment: info;
        content: counter(info);

        position: absolute;
        left: 0;
        top: 7px;

        display: flex;
        width: 40px;
        height: 40px;
        background: $color-main;
        color: $color-white;
        border-radius: 6px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;

        justify-content: center;
        align-items: center;

        @include respond-to(tablet) {
          top: 6px;
        }
      }
    }

    &-icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      border: 2px solid $color-main;

      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-to(tablet) {
        width: 150px;
        height: 150px;
      }

      @include respond-to(macbook) {
        position: static;
        margin: 0 0 12px;
      }

      svg {
        width: 40px;
        fill: $color-main;

        @include respond-to(tablet) {
          width: 70px;
        }
      }
    }

    &-count {
      font-size: 30px;
      line-height: 1.3;
      font-weight: 700;

      @include respond-to(tablet) {
        font-size: 36px;
      }
    }

    &-label {
      font-size: 16px;
      line-height: 1.25;

      .info_counter & {
        font-size: 14px;
        line-height: 1.9;

        @include respond-to(tablet) {
          font-size: 16px;
          line-height: 1.625;
        }
      }

      .info_space & {
        @include respond-to(macbook) {
          width: 220px;
          transform: translate(-35px, 0);
        }
      }

      .info__item_wide & {
        @include respond-to(macbook) {
          width: 330px;
          transform: translate(-85px, 0);
        }
      }

      &-title {
        font-size: 16px;
        line-height: 1.25;
        font-weight: 700;
        text-transform: uppercase;

        padding: 7px 0;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        height: 40px;

        @include respond-to(tablet) {
          font-size: 20px;
          line-height: 1.35;
          padding-top: 6px;
        }

        @include respond-to(macbook) {
          padding-top: 6px;
        }
      }

      &-text {
        padding: 10px 0;

        a {
          color: $color-main;
        }

        @include respond-to(tablet) {
          padding: 15px 0;
        }
      }
    }

    &-decor {
      position: absolute;
      width: 2px;
      background: $color-main;
      top: 100px;
      bottom: 0px;
      left: 50px;
      margin: 10px 0 0 -1px;

      .info_counter & {
        left: 20px;
        top: 50px;
        margin: 7px 0 0 -1px;
      }

      @include respond-to(tablet) {
        display: none;

        .info_counter & {
          display: block;
          margin-top: 6px;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: $color-main;

        position: absolute;
        left: 50%;
        margin-left: -5px;
        top: 0;
      }

      &:after {
        top: auto;
        bottom: 0;

        @include respond-to(macbook) {
          display: none;

          .info_counter & {
            display: block;
          }
        }
      }

      .info__item:last-child & {
        display: none;
      }
    }
  }
}
