.btn_small-icon {
  .ant-spin {
    .anticon {
      width: 20px;
      height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.btn__more {
  margin: 30px 0 0;

  @include respond-to(tablet) {
    margin: 40px 0 0;
  }
}
