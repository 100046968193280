.slider-disciplines {
  position: relative;

  &__item {
    margin: 0 0 5px;
  }

  &__name {
    text-transform: none;
  }

  &__text {
    max-width: 892px;
    margin: 16px auto 0;
  }
}
