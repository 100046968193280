.gsc-control-cse,
.gsc-control-cse .gsc-table-result {
  font-family: $global-font-family !important;
}

.gsc-input-box input {
  height: 36px !important;
  font-size: 16px;
  font-weight: 600;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 1px 13px 0 !important;
}

.gsc-input-box {
  border: 2px solid $color-main !important;
  border-right-width: 0 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  &:hover,
  &:focus {
    border-width: 2px !important;
    border-right-width: 0 !important;
  }

  table,
  tbody,
  tr,
  td {
    height: 36px !important;
  }
}

table.gsc-search-box td.gsc-input {
  padding: 0 !important;
}

.gsc-search-box table.gsc-search-box {
  border-collapse: collapse;
  border-spacing: 0;

  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: middle;
  }
}

.gsst_a .gscb_a {
  transition: $global-transition;
}

td.gsc-search-button {
  height: 1px !important;
}

button.gsc-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 100%;
  border-color: $color-main;
  background: $color-main;
  box-shadow: none;
  border-radius: 0 10px 10px 0;
  padding: 0 !important;
  margin: 0 !important;
  transition: $global-transition;

  &:hover {
    border-color: $color-main-hover;
    background: $color-main-hover;
  }

  span,
  svg {
    width: 20px;
    height: 20px;
  }

  svg {
    fill: $color-white;
  }
}

.gsc-table-result {
  margin-top: 5px !important;
  max-width: 892px;
}

.gs-web-image-box,
.gs-promotion-image-box {
  margin-right: 5px !important;
}
