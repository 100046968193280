.ya-share2__badge {
  background-color: $color-main !important;

  margin-right: 5px;
  border-radius: 3px;

  &,
  & > .ya-share2__icon {
    width: 20px !important;
    height: 20px !important;
    background-size: 20px 20px !important;
  }

  &:hover {
    background-color: $color-main-hover !important;
  }
}
