.tile {
  @include respond-to(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 40px;
  }

  &_three {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    margin: 0 0 30px;

    &:last-child {
      margin: 0;
    }

    @include respond-to(tablet) {
      margin: 0;
    }

    &-pic {
      border-radius: 10px;
      margin: 0 0 20px;
      position: relative;

      overflow: hidden;

      &:hover {
        .tile__item-pic-backdrop {
          opacity: 1;
          visibility: visible;
        }
      }

      img {
        display: block;
        width: 100%;
        margin: 0;
      }

      &-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($color-white, 0.75);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        opacity: 0;
        visibility: hidden;

        transition: all $transition-time * 2 ease;
      }

      &-download {
        &-label {
          font-size: 20px;
          font-weight: 700;
          margin: 0 0 20px;
        }

        &-btns {
          display: flex;
        }

        &-btn {
          display: flex;
          align-items: center;
          padding: 0 30px;
          margin: 0 10px;

          &:last-child {
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    &-title {
      text-align: center;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 700;
    }
  }
}
