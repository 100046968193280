.widget-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: $color-blue;
  border-radius: 100%;
  border: 2px solid $color-white;
  transition: $global-transition;
  z-index: 99;

  @include respond-to(tablet) {
    right: 30px;
    bottom: 30px;
  }

  @include respond-to(macbook) {
    right: 60px;
    bottom: 60px;
  }

  &:hover {
    border-color: inherit;

    @include respond-to(macbook) {
      .widget-btn__hint {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  svg {
    width: 32px;
  }

  &__hint {
    position: absolute;
    right: 100%;
    top: 50%;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
    color: $color-black;
    align-items: center;
    height: 38px;
    border: 2px solid $color-blue;
    background: $color-white;
    padding: 0 10px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transform: translate(-20px, -50%);
    transition: all 0.25s ease;
  }
}
