.main {
  &__about-title {
    font-style: italic;
    font-weight: 700;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
    }
  }

  &__intro {
    position: relative;

    &-title {
      @include respond-to(macbook) {
        font-size: 50px;
        line-height: 1.2;
      }
    }
  }
}
